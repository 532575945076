import { render, staticRenderFns } from "./SportsSpecial.vue?vue&type=template&id=62e9eddd&scoped=true&"
import script from "./SportsSpecial.vue?vue&type=script&lang=js&"
export * from "./SportsSpecial.vue?vue&type=script&lang=js&"
import style0 from "./SportsSpecial.vue?vue&type=style&index=0&id=62e9eddd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e9eddd",
  null
  
)

export default component.exports